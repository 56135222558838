/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Contribute" donationid="34"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Heartfulness Pune Donation"/>';
const PageContent = React.memo(PageContentNonMemoized);

const GeneralPuneFund = () => {
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("pune-fund", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="General Fund - Panshet Retreat Centre,Pune"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Heartfulness Donations</h3>}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3 }}>
          General Fund - Pune Retreat Centre of Excellence, Pune, Maharashtra,
          India
        </h3>
        <p>
          Pune Centre of Excellence is located at Panshet overlooking the
          backwaters of Khadakwasla dam. It is away from the hustle and bustle
          of modern life and has a serene and peaceful natural environment.
        </p>
        <p>
          The Centre of Excellence is involved in multiple volunteer-run
          activities for the betterment of mankind and society. We do not charge
          any fees for these programs. Your valuable donation will be used for,
        </p>
        <Box>
          <Box sx={{ mb: 3, mt: 3 }}>
            <ul>
              <li sx={{ marginBottom: "0.5rem" }}>
                &bull; Self development programs for students and faculty of
                Schools, Colleges and Universities.
              </li>
              <li sx={{ marginBottom: "0.5rem" }}>
                &bull; Training programs for people from various walks of life -
                professionals, employees, senior citizens and children
              </li>
              <li sx={{ marginBottom: "0.5rem" }}>
                &bull; Free medical clinic for economically disadvantaged
                sections of rural areas in the vicinity of Panshet.
              </li>
              <li sx={{ marginBottom: "0.5rem" }}>
                &bull; Plant nursery for endangered species of plants and trees.
                This nursery supports the afforestation program in Pune
                district.
              </li>
              <li sx={{ marginBottom: "0.5rem" }}>
                &bull; Mental wellness programs for citizens of the world.
              </li>
            </ul>
          </Box>
          <h4
            sx={{
              fontFamily: "gotham-book",
              fontSize: "18px",
              lineHeight: "1.5",
              marginBottom: "1rem",
            }}
          >
            All donations are tax-exempted as eligible under section 80G of the
            the Income Tax Act, 1961
          </h4>
          <h4
            sx={{
              fontFamily: "gotham-book",
              fontSize: "18px",
              lineHeight: "1.5",
              marginBottom: "1rem",
            }}
          >
            Anyone who wish to contribute towards Pune Retreat Centre may do so
            using below link.
          </h4>
        </Box>
        <br />
        <br />
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
      </Box>
    </LayoutHome>
  );
};

export default GeneralPuneFund;
